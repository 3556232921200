import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private tokenKey = environment.TOKEN_KEY;
  private baseUrl = environment.apiUrl;
  constructor(
    private authenticationClient: AuthService,
    private router: Router,
    private toaster: ToastrService,
    private httpClient : HttpClient
  ) { }

  public login(input: any) {
    return this.authenticationClient.login(input);
    // this.authenticationClient.login(input).subscribe((res) => {
    //   console.log("token", res)
    //   // if (res['success']) {
    //   localStorage.setItem(this.tokenKey, res?.token);
    //   this.toaster.success('', 'Logged In successfully');
    //   this.router.navigate(['/dashboard']);
    //   // }
    // }, (err) => {
    //   console.log(err);
    //   this.toaster.error('Try valid credentials', 'Failed to Log In');

    // });
  }

  public register(input: any) {
    return this.authenticationClient.register(input);
  }

  public logout() {
    localStorage.removeItem(this.tokenKey);
    this.router.navigate(['/auth/login']);
  }

  public isLoggedIn(): boolean {
    let token = localStorage.getItem(this.tokenKey);
    return token != null && token.length > 0;
  }

  public getToken(): string | null {
    return this.isLoggedIn() ? localStorage.getItem(this.tokenKey) : null;
  }

  getLoggedInUser(){
    return this.httpClient.get(`${this.baseUrl}/auth/current-user`,);
  }



}