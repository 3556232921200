import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../app/auth/auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private logoutTimer: any;
  private readonly TIMEOUT = 10 * 60 * 1000; // 10 minutes in milliseconds

  constructor(private router: Router,private auth:AuthenticationService) {
    this.resetTimer();
    this.initListener();
  }

  initListener() {
    window.addEventListener('mousemove', () => this.resetTimer());
    window.addEventListener('keydown', () => this.resetTimer());
    window.addEventListener('click', () => this.resetTimer());
    window.addEventListener('touchstart', () => this.resetTimer());
  }

  resetTimer() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
    }

    this.logoutTimer = setTimeout(() => {
      this.auth.logout();
    }, this.TIMEOUT);
  }

}
