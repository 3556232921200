<input [id]="id" type="file" style="display:none" class="form-control" accept="image/*" maxFileSize="26214400" placeholder=""
    (change)="onFileChange($event)" />

<div *ngIf="base64 ;else textShow"
    class="row align-items-center d-flex justify-content-center showImage border p-0 px-5 mb-2 position-relative">
    <div class="position-relative w-auto">
        <div class="position-absolute end-0">
            <nb-icon (click)="deleteImage()" [options]="{ animation: { type: 'pulse' } }" title="remove file"
                class="border cursor-pointer h-35px rounded-circle w-35px bg-white" nbPrefix [icon]="'close-outline'"
                pack="eva"></nb-icon>
        </div>
        <img class="img-fluid {{classes}} {{classes?.trim()?.length?'custom-size':''}}" [src]="base64">
    </div>
</div>

<ng-template #textShow>
    <div class="{{row?'row':''}} border align-items-center h-110 px-6 blog-image-upload">
        <div class="col-12 justify-content-center text-center py-3 d-flex flex-column align-items-center">

            <div class="text-center">
                <nb-icon (click)="fileButton()" [options]="{ animation: { type: 'pulse' } }"
                    class="h-35px w-35px cursor-pointer" nbPrefix [icon]="icon" pack="eva"></nb-icon>

            </div>
            <h2 (click)="fileButton()" class="text-muted  cursor-pointer _h2">{{title}}</h2>
            <div class="text-center">
                <small class="text-muted">{{hint}}</small>
            </div>

        </div>
    </div>

</ng-template>