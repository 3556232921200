import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NbIconModule } from '@nebular/theme';
import { DeleteFile, UploadFile } from '../pages/site-pages/modals/file-upload-ui.modal';

@Component({
  selector: 'ngx-custom-file-upload-ui',
  templateUrl: './custom-file-upload-ui.component.html',
  styleUrls: ['./custom-file-upload-ui.component.scss'],
  standalone: true,
  imports: [NbIconModule, CommonModule]
})
export class CustomFileUploadUiComponent implements OnInit, OnChanges {
  @Input() title = 'Upload Title Image';
  @Input() icon = 'cloud-upload-outline';
  @Input() hint = 'This will be shown on Blog timeline';
  @Input() base64 = '';
  @Input() id = 'file-upload-ui-ref';
  @Input() row = true;
  @Input() classes = '';
  /** 
   * @Note
    This Component require unique ID if there are more than once instance/selector of this component in other comps 
  */


  @Output() fileDelete = new EventEmitter<DeleteFile>();
  @Output() fileUpload = new EventEmitter<UploadFile>();
  @Output() srcChange = new EventEmitter<{ base64: string }>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.base64) {
      this.onUrlChange();
    }
  }

  onFileChange(event: any) {
    let imageFile: File = event?.target?.files?.length ? event?.target?.files[0] : null;
    var fileReader = new FileReader();
    fileReader.onload = (res) => {
      this.base64 = res?.target?.result as string;
      this.fileUpload.emit({ base64: this.base64, imageFile: imageFile, fileUploaded: true });
    };

    fileReader.readAsDataURL(imageFile);
  }

  fileButton() {
    document.getElementById(this.id)?.click()
  }

  onUrlChange() {
    this.srcChange.emit({ base64: this.base64 });
  }



  deleteImage() {
    this.base64 = '';
    this.fileDelete.emit({ base64: this.base64, fileDeleted: true });
  }

}
