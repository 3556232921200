import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs'; // Import Subject from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl;
  user: BehaviorSubject<any> = new BehaviorSubject<any>(null); // Use Subject instead of subject
  setUser(user: any): void {
    this.user.next(user);
  }

  getUser(): Observable<any> {

    return this.user.asObservable();
  }
  constructor(private httpClient : HttpClient) { }

  public login(input:any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/auth/login', input);
  }

  register(input:any): Observable<any>{
    return this.httpClient.post<any>(this.baseUrl + '/auth/register', input);
  }

  getLoggedInUser(): Observable<any>{
    const token = JSON.parse(localStorage.getItem(environment.TOKEN_KEY))
    let httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.httpClient.get<any>(this.baseUrl + '/current-user', {
      headers: httpHeaders
    });
  }


}
