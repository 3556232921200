import { Component } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss','../../../../app/custom-css/color-changing-dots.css'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <div *ngIf="isShowingRouteLoadIndicator" class='position-absolute text-muted font-size-18px fw-bold' style="left:50%">
          <!-- Loading...  -->
          <span class="aligned-text-with-dots" >
          Loading&nbsp;

                                    <div class="color-changing-dots">
                                        <div class="dot"></div>
                                        <div class="dot"></div>
                                        <div class="dot"></div>
                                    </div>
                                </span>

        </div>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})
// <nb-layout-footer fixed>
//         <ngx-footer></ngx-footer>
//       </nb-layout-footer>
export class OneColumnLayoutComponent {

  isShowingRouteLoadIndicator = false;
  constructor(private router: Router) {
    this.isShowingRouteLoadIndicator = false;
    var asyncLoadCount = 0;
    router.events.subscribe((val: RouterEvent) => {
      if (val instanceof RouteConfigLoadStart) {
        asyncLoadCount++;
      }
      else if (val instanceof RouteConfigLoadEnd) {
        asyncLoadCount--;
      }
      this.isShowingRouteLoadIndicator = !!asyncLoadCount;
      // console.log('asyncLoadCount', asyncLoadCount);
    });
  }
}
